// extracted by mini-css-extract-plugin
export var GalleryDesktop = "GalleryDesktop-module--GalleryDesktop--HwwaF";
export var Left = "GalleryDesktop-module--Left--ajt9a";
export var Wrap = "GalleryDesktop-module--Wrap--MhdZV";
export var Arrow = "GalleryDesktop-module--Arrow--Hozug";
export var IconRightWrap = "GalleryDesktop-module--IconRightWrap--4U4b4";
export var Right = "GalleryDesktop-module--Right--1ANiY";
export var ImageWrap = "GalleryDesktop-module--ImageWrap--eH7lV";
export var Image = "GalleryDesktop-module--Image--NYcHu";
export var animationImg = "GalleryDesktop-module--animationImg--Hx-8p";
export var arrowLeft = "GalleryDesktop-module--arrowLeft--++sYv";
export var arrowRight = "GalleryDesktop-module--arrowRight--q9kh7";