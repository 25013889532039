import React, { useContext, useEffect } from 'react'
import * as classes from './Location.module.scss'
import cn from 'classnames'
import Container from '../../../ui/Container/Container'
import SectionTitle from '../../../ui/SectionTitle/SectionTitle'
import { deleteHtmlTag } from '../../../../utils/deleteHtmlTag'
import splitRow from '../../../../utils/splitRow'
import { startAnimationScroll } from '../../../../utils/animations'
import { FormContext } from './../../../../context/index'

function Location({ data, titleSection, lang }) {
    const { animationStart } = useContext(FormContext)

    const locationTextLeft = deleteHtmlTag(data.locationTextLeft, 'p')
    const locationTextRight = deleteHtmlTag(data.locationTextRight, 'p')
    const sectionTitle = deleteHtmlTag(titleSection.value, 'p')

    const classAnimationText = 'anime-text-projects-location'

    useEffect(() => {
        if (animationStart) {
            splitRow(classAnimationText)
        }

        const observer = startAnimationScroll([{ type: 'text', className: classAnimationText }])

        return () => {
            if (observer) {
                observer.disconnect()
            }
        }
    }, [animationStart])

    return (
        <section className={cn(classes.Section, {[classes.LangHY]: lang === 'hy'})}>
            <Container>
                {sectionTitle ? <SectionTitle title={sectionTitle} lang={lang} /> : null}

                {locationTextLeft || locationTextRight ? (
                    <div className={classes.Inner}>
                        {locationTextLeft ? (
                            <p className={`${classes.Text} ${classAnimationText}`} dangerouslySetInnerHTML={{ __html: locationTextLeft }} />
                        ) : null}

                        {locationTextRight ? (
                            <p
                                className={`${classes.Text} ${classAnimationText}`}
                                dangerouslySetInnerHTML={{ __html: locationTextRight }}
                            />
                        ) : null}
                    </div>
                ) : null}
            </Container>
        </section>
    )
}

export default Location
