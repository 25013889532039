import { graphql } from 'gatsby'
import React from 'react'
import Layout from './../components/help/Layout/Layout'
import Seo from '../components/help/Seo'
import Header from './../components/ui/Header/Header'
import HeaderFixed from './../components/ui/HeaderFixed/HeaderFixed'
import { getHrefToHome } from '../utils/getHrefToHome'
import Intro from './../components/ui/Intro/Intro'
import About from '../components/pages/Projects/About/About'
import Location from '../components/pages/Projects/Location/Location'
import Apartments from '../components/pages/Projects/Apartments/Apartments'
import Features from '../components/pages/Projects/Features/Features'
import NextProject from '../components/pages/Projects/NextProject/NextProject'
import PageNavigation from './../components/ui/PageNavigation/PageNavigation'
import { useDataHeaderByEn } from '../components/ui/Header/useDataHeaderByEn'
import { useDataHeaderByHy } from '../components/ui/Header/useDataHeaderByHy'

const ProjectPageDetail = ({ data, pageContext, location }) => {
    const { idNextProject } = pageContext

    const isLangByHY = location.pathname.split('/')[1] !== 'en'
    const lang = isLangByHY ? 'hy' : 'en'

    const hrefHome = getHrefToHome(lang)
    const breadCrumbProjects = lang === 'hy' ? `/projects/` : `/en/projects/`

    const breadCrumbs = [
        { name: data.seoMain.crumbsName, href: `${hrefHome}`, current: false },
        { name: data.seo.crumbsName, href: `${breadCrumbProjects}`, current: true }
    ]

    const photo = {
        1920: data.result.bgDesktop.url,
        1440: data.result.bgDesktop.url,
        1024: data.result.bgTabletA?.url,
        768: data.result.bgTabletP?.url,
        360: data.result.bgMobile?.url
    }

    const menyByHY = useDataHeaderByHy()
    const menyByEN = useDataHeaderByEn()
    const menuHeader = lang === 'hy' ? menyByHY.menu.nodes : menyByEN.menu.nodes

    return (
        <Layout lang={lang}>
            <Seo
                title={data.result.seo && data.result.seo.title ? data.result.seo.title : data.data.title}
                description={data.result.seo && data.result.seo.description ? data.result.seo.description : ''}
            />

            <Header light lang={lang} menuHeader={menuHeader} />
            <HeaderFixed light lang={lang} />
            <Intro crumbs={breadCrumbs} title={data.result.title} photo={photo} lang={lang} showBackArrow />
            <About data={data.result} titleSection={data.titleAbout} lang={lang} />
            <Location data={data.result} titleSection={data.titleLocation} lang={lang} />
            <Apartments data={data.result} titleSection={data.titleApartments} lang={lang} />
            <Features data={data.result} titleSection={data.titleFeatures} lang={lang} />

            {idNextProject ? (
                <NextProject
                    lang={lang}
                    data={data.nextProject}
                    title={data.titleNext}
                    textLocation={data.nextProjectLocation && data.nextProjectLocation.value ? data.nextProjectLocation.value : false}
                />
            ) : null}

            <PageNavigation links={data.seo.footerLinks} miniTopPadding />
        </Layout>
    )
}

export const query = graphql`
    query pageProjectQuery($pageId: String, $idNextProject: String, $pageLang: String) {
        result: datoCmsRenshinProject(originalId: { eq: $pageId }, locale: { eq: $pageLang }) {
            title
            aboutTextTop
            aboutTextBottom
            aboutPhotoLeft {
                url
            }
            aboutPhotoRight {
                url
            }
            locationTextLeft
            locationTextRight
            galleryDesktop {
                url
            }
            galleryMobile {
                url
            }
            apartments
            featuresPhoto {
                url
            }
            featuresText
            bgDesktop {
                url
            }
            bgTabletA {
                url
            }
            bgTabletP {
                url
            }

            bgMobile {
                url
            }
            seo {
                title
                description
            }
        }

        nextProject: datoCmsRenshinProject(originalId: { eq: $idNextProject }, locale: { eq: $pageLang }) {
            originalId
            title
            previewText
            location
            urlCode
            previewPictureDesktop {
                url
            }
        }

        titleAbout: datoCmsRenshinPageProject(title: { eq: "Title: About" }, locale: { eq: $pageLang }) {
            value
        }

        titleLocation: datoCmsRenshinPageProject(title: { eq: "Title: Location" }, locale: { eq: $pageLang }) {
            value
        }

        titleApartments: datoCmsRenshinPageProject(title: { eq: "Title: Apartments" }, locale: { eq: $pageLang }) {
            value
        }

        titleFeatures: datoCmsRenshinPageProject(title: { eq: "Title: Features" }, locale: { eq: $pageLang }) {
            value
        }

        titleNext: datoCmsRenshinPageProject(title: { eq: "Title: Next" }, locale: { eq: $pageLang }) {
            value
        }

        nextProjectLocation: datoCmsRenshinPageProject(title: { eq: "Text: Location" }, locale: { eq: $pageLang }) {
            value
        }

        seo: datoCmsRenshinSeo(pageName: { eq: "Key projects" }, locale: { eq: $pageLang }) {
            titleH1
            crumbsName
            bgDesktop {
                url
            }
            footerLinks {
                title
                url
            }
            seo {
                title
                description
            }
        }

        seoMain: datoCmsRenshinSeo(pageName: { eq: "Main" }, locale: { eq: $pageLang }) {
            crumbsName
        }
    }
`

export default ProjectPageDetail
