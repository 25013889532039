import React, { useContext, useEffect, useState } from 'react'
import * as classes from './Apartments.module.scss'
import Container from '../../../ui/Container/Container'
import SectionTitle from '../../../ui/SectionTitle/SectionTitle'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper/core'
import { deleteHtmlTag } from '../../../../utils/deleteHtmlTag'
import GalleryDesktop from './GalleryDesktop/GalleryDesktop'
import { FormContext } from './../../../../context/index'
import splitRow from '../../../../utils/splitRow'
import { startAnimationScroll } from '../../../../utils/animations'

SwiperCore.use([Pagination])

function Apartments({ data, titleSection, lang }) {
    const { animationStart } = useContext(FormContext)

    const [swiperShow, setSwiperShow] = useState(false)
    const [swiper, setSwiper] = useState(null)

    const apartments = deleteHtmlTag(data.apartments, 'p')
    const sectionTitle = deleteHtmlTag(titleSection.value, 'p')

    const classAnimationText = 'anime-text-projects-apartments'

    useEffect(() => {
        if (animationStart) {
            splitRow(classAnimationText)
            setSwiperShow(true)
        }

        const observer = startAnimationScroll([{ type: 'text', className: classAnimationText }])

        return () => {
            if (observer) {
                observer.disconnect()
            }
        }
    }, [animationStart])

    return (
        <section className={classes.Section}>
            {data.galleryDesktop && data.galleryDesktop.length > 1 ? <GalleryDesktop data={data.galleryDesktop} /> : null}

            {data.galleryMobile && data.galleryMobile.length && swiperShow ? (
                <div className={classes.SliderMobail} id='ApartmentsSliderMobail'>
                    <Swiper
                        onSwiper={setSwiper}
                        speed={400}
                        slidesPerView={'auto'}
                        loop
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 0,
                                pagination: {
                                    type: 'bullets',
                                    clickable: true,
                                    el: `.${classes.Bullets}`
                                }
                            }
                        }}
                    >
                        {data.galleryMobile.map((item, index) => (
                            <SwiperSlide className={classes.Slide} key={index}>
                                <div className={classes.Image} style={{ backgroundImage: `url(${item.url})` }} />
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <div className={classes.Bullets} />
                </div>
            ) : null}

            {sectionTitle || apartments ? (
                <Container>
                    {sectionTitle ? <SectionTitle title={sectionTitle} lang={lang} /> : null}

                    {apartments ? (
                        <p className={`${classes.Desc} ${classAnimationText}`} dangerouslySetInnerHTML={{ __html: apartments }} />
                    ) : null}
                </Container>
            ) : null}
        </section>
    )
}

export default Apartments
