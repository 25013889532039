import React, { useContext, useEffect } from 'react'
import * as classes from './About.module.scss'
import cn from 'classnames'
import Container from '../../../ui/Container/Container'
import SectionTitle from '../../../ui/SectionTitle/SectionTitle'
import { deleteHtmlTag } from '../../../../utils/deleteHtmlTag'
import { FormContext } from './../../../../context/index'
import splitRow from './../../../../utils/splitRow'
import { startAnimationScroll } from './../../../../utils/animations'

function About({ data, titleSection, lang }) {
    const { animationStart } = useContext(FormContext)

    const aboutTextTop = deleteHtmlTag(data.aboutTextTop, 'p')
    const aboutTextBottom = deleteHtmlTag(data.aboutTextBottom, 'p')
    const sectionTitle = deleteHtmlTag(titleSection.value, 'p')

    const classAnimationText = 'anime-text-projects-about'

    useEffect(() => {
        if (animationStart) {
            splitRow(classAnimationText)
        }

        const observer = startAnimationScroll([{ type: 'text', className: classAnimationText }])

        return () => {
            if (observer) {
                observer.disconnect()
            }
        }
    }, [animationStart])

    return (
        <section className={cn(classes.Section, { [classes.LangHY]: lang === 'hy' })}>
            <Container>
                {sectionTitle ? <SectionTitle title={sectionTitle} lang={lang} /> : null}

                {aboutTextTop ? (
                    <p className={`${classes.Desc} ${classAnimationText}`} dangerouslySetInnerHTML={{ __html: aboutTextTop }} />
                ) : null}

                {(data.aboutPhotoLeft && data.aboutPhotoLeft.url) ||
                (data.aboutPhotoRight && data.aboutPhotoRight.url) ||
                aboutTextBottom ? (
                    <div className={classes.Inner}>
                        {(data.aboutPhotoLeft && data.aboutPhotoLeft.url) || aboutTextBottom ? (
                            <div className={classes.Left}>
                                {data.aboutPhotoRight && data.aboutPhotoRight.url ? (
                                    <div className={classes.LeftImage} style={{ backgroundImage: `url(${data.aboutPhotoLeft.url})` }} />
                                ) : null}

                                {aboutTextBottom ? (
                                    <p
                                        className={`${classes.LeftText} ${classAnimationText}`}
                                        dangerouslySetInnerHTML={{ __html: aboutTextBottom }}
                                    />
                                ) : null}
                            </div>
                        ) : null}

                        {data.aboutPhotoRight && data.aboutPhotoRight.url ? (
                            <div className={classes.Right}>
                                <div className={classes.RightImage} style={{ backgroundImage: `url(${data.aboutPhotoRight.url})` }} />
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </Container>
        </section>
    )
}

export default About
