import React, { useEffect } from 'react'
import * as classes from "./GalleryDesktop.module.scss"
import IconRight from '../../../../icons/IconRight'
import cn from 'classnames'

export default function GalleryDesktop({data}) {
    const slideNext = () => {
        if (
            document.querySelector('[data-project-img-left-container="Y"] [data-active="Y"]') &&
            document.querySelector('[data-project-img-right-container="Y"] [data-active="Y"]')
        ) {
            const slideCount = data.length

            let idLeftCurrent = document.querySelector('[data-project-img-left-container="Y"] [data-active="Y"]').getAttribute('data-id')
            let idLeftNext = false

            let idRightCurrent = document.querySelector('[data-project-img-right-container="Y"] [data-active="Y"]').getAttribute('data-id')
            let idRightNext = false

            if (idLeftCurrent && idRightCurrent) {
                idLeftCurrent = parseInt(idLeftCurrent)
                idRightCurrent = parseInt(idRightCurrent)

                if (idLeftCurrent === slideCount) {
                    idLeftNext = 1
                } else {
                    idLeftNext = idLeftCurrent + 1
                }

                if (idRightCurrent === slideCount) {
                    idRightNext = 1
                } else {
                    idRightNext = idRightCurrent + 1
                }

                if (idLeftCurrent && idLeftNext && idRightCurrent && idRightNext) {
                    document.querySelector(`[data-project-img-left-container="Y"] [data-id="${idLeftCurrent}"]`).style.zIndex = '3'
                    document.querySelector(`[data-project-img-left-container="Y"] [data-id="${idLeftCurrent}"]`).removeAttribute('data-active')
                    document.querySelector(`[data-project-img-left-container="Y"] [data-id="${idLeftCurrent}"]`).setAttribute('data-animation', 'Y')
                    document.querySelector(`[data-project-img-left-container="Y"] [data-id="${idLeftNext}"]`).style.zIndex = '2'

                    document.querySelector(`[data-project-img-right-container="Y"] [data-id="${idRightCurrent}"]`).style.zIndex = '3'
                    document.querySelector(`[data-project-img-right-container="Y"] [data-id="${idRightCurrent}"]`).removeAttribute('data-active')
                    document.querySelector(`[data-project-img-right-container="Y"] [data-id="${idRightCurrent}"]`).setAttribute('data-animation', 'Y')
                    document.querySelector(`[data-project-img-right-container="Y"] [data-id="${idRightNext}"]`).style.zIndex = '2'

                    setTimeout(() => {
                        document.querySelector(`[data-project-img-left-container="Y"] [data-id="${idLeftCurrent}"]`).removeAttribute('style')
                        document.querySelector(`[data-project-img-left-container="Y"] [data-id="${idLeftCurrent}"]`).removeAttribute('data-animation')

                        document.querySelector(`[data-project-img-right-container="Y"] [data-id="${idRightCurrent}"]`).removeAttribute('style')
                        document.querySelector(`[data-project-img-right-container="Y"] [data-id="${idRightCurrent}"]`).removeAttribute('data-animation')

                        document.querySelector(`[data-project-img-left-container="Y"] [data-id="${idLeftNext}"]`).removeAttribute('style')
                        document.querySelector(`[data-project-img-left-container="Y"] [data-id="${idLeftNext}"]`).setAttribute('data-active', 'Y')

                        document.querySelector(`[data-project-img-right-container="Y"] [data-id="${idRightNext}"]`).removeAttribute('style')
                        document.querySelector(`[data-project-img-right-container="Y"] [data-id="${idRightNext}"]`).setAttribute('data-active', 'Y')
                    }, 530)
                }
            }
        }
    }

    return (
        <div className={classes.GalleryDesktop}>
            <div className={classes.Left}>
                <div
                    className={classes.Wrap}
                    data-project-img-left-container="Y"
                >
                    {data.map((item, index) => (
                        <div
                            key={index}
                            className={`${classes.ImageWrap} ${index === 0 ? classes.Active : ''}`}
                            data-id={index + 1}
                            data-active={index === 0 ? "Y" : null}
                        >
                            <div
                                className={classes.Image}
                                style={{ backgroundImage: `url(${item.url})` }}
                                data-img="Y"
                            />
                        </div>
                    ))}

                    <button
                        className={classes.Arrow}
                        onClick={slideNext}
                    >
                        <p className={classes.IconRightWrap}>
                            <IconRight />
                        </p>
                    </button>
                </div>
            </div>

            <div className={classes.Right}>
                <div
                    className={cn(classes.Wrap, 'js-block-dark')}
                    data-project-img-right-container="Y"
                >
                    {data.map((item, index) => (
                        <div
                            key={index}
                            className={`${classes.ImageWrap} ${index === 1 ? classes.Active : ''}`}
                            data-id={index + 1}
                            data-active={index === 1 ? "Y" : null}
                        >
                            <div
                                className={classes.Image}
                                style={{ backgroundImage: `url(${item.url})` }}
                                data-img="Y"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}