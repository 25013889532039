// extracted by mini-css-extract-plugin
export var Section = "Apartments-module--Section--Q-S-0";
export var SliderMobail = "Apartments-module--SliderMobail--5T0US";
export var Image = "Apartments-module--Image--Z1aI1";
export var Active = "Apartments-module--Active--iR3Gr";
export var Arrow = "Apartments-module--Arrow--nuSSd";
export var Desc = "Apartments-module--Desc--brfWo";
export var Slider = "Apartments-module--Slider--ShRy6";
export var Dots = "Apartments-module--Dots--2dmSU";
export var Bullets = "Apartments-module--Bullets--d-UaR";
export var arrowLeft = "Apartments-module--arrowLeft--W0l78";
export var arrowRight = "Apartments-module--arrowRight--448JJ";