// extracted by mini-css-extract-plugin
export var Section = "Features-module--Section--4YSaG";
export var LangHY = "Features-module--LangHY--4pRH3";
export var ItemText = "Features-module--ItemText--WxSjM";
export var Inner = "Features-module--Inner--jWR0I";
export var Separator = "Features-module--Separator--cFi0+";
export var Image = "Features-module--Image--78+HC";
export var Items = "Features-module--Items--V+70f";
export var Item = "Features-module--Item--0MWi+";
export var Line = "Features-module--Line--ZKtac";
export var ItemNum = "Features-module--ItemNum--Y3Q0X";
export var arrowLeft = "Features-module--arrowLeft--ojTWi";
export var arrowRight = "Features-module--arrowRight--rtKGo";