// extracted by mini-css-extract-plugin
export var Section = "About-module--Section--TGZL+";
export var LangHY = "About-module--LangHY--MrEEn";
export var LeftText = "About-module--LeftText--TVhLw";
export var Desc = "About-module--Desc--GoX7f";
export var Inner = "About-module--Inner--yg1aD";
export var Left = "About-module--Left--Uzm5k";
export var LeftImage = "About-module--LeftImage--kPSdP";
export var Right = "About-module--Right--DQDod";
export var RightImage = "About-module--RightImage--8EwHr";
export var arrowLeft = "About-module--arrowLeft--Q4U05";
export var arrowRight = "About-module--arrowRight--SCPIr";