// extracted by mini-css-extract-plugin
export var Section = "NextProject-module--Section--cMzCL";
export var Title = "NextProject-module--Title--1VRZ-";
export var Item = "NextProject-module--Item--Qksrz";
export var LangHY = "NextProject-module--LangHY--Wsdh6";
export var ItemDesc = "NextProject-module--ItemDesc--j6BoR";
export var ItemLocation = "NextProject-module--ItemLocation--gw09K";
export var Separator = "NextProject-module--Separator--cd8Lu";
export var ItemImage = "NextProject-module--ItemImage--hz6Wi";
export var ItemContent = "NextProject-module--ItemContent--dcg9R";
export var ItemTitle = "NextProject-module--ItemTitle--oFnqY";
export var ItemFooter = "NextProject-module--ItemFooter--Dyvk9";
export var ItemDetails = "NextProject-module--ItemDetails--sbSK3";
export var IconRightWrap = "NextProject-module--IconRightWrap--MBKSt";
export var arrowLeft = "NextProject-module--arrowLeft--2etha";
export var arrowRight = "NextProject-module--arrowRight--FPrE1";